import _getSubscriberLabel_SettingsUI_SubscriberUploadJobParameters from "./__generated__/getSubscriberLabel_SettingsUI_SubscriberUploadJobParameters.graphql";
import { graphql, readInlineData } from '@attentive/data';
import GetSubscriberLabelFragment from './__generated__/getSubscriberLabel_SettingsUI_SubscriberUploadJobParameters.graphql';
import { SubscriptionTypeName } from '../components/SubscriberUploadDialog/reducers';
_getSubscriberLabel_SettingsUI_SubscriberUploadJobParameters;
export function getSubscriberLabel(queryRef) {
  if (!queryRef) {
    return 'N/A';
  }

  const {
    strategy,
    subscriptionTypes
  } = readInlineData(GetSubscriberLabelFragment, queryRef);

  if (strategy === 'SUBSCRIBER_UPDATE_STRATEGY_UPDATE_EXISTING') {
    return 'Existing subscriber update';
  }

  switch (subscriptionTypes) {
    case 'SUBSCRIPTION_TYPE_EMAIL_MARKETING':
      return SubscriptionTypeName.SUBSCRIPTION_TYPE_EMAIL_MARKETING;

    case 'SUBSCRIPTION_TYPE_EMAIL_TRANSACTIONAL':
      return SubscriptionTypeName.SUBSCRIPTION_TYPE_EMAIL_TRANSACTIONAL;

    case 'SUBSCRIPTION_TYPE_TEXT_MARKETING':
      return SubscriptionTypeName.SUBSCRIPTION_TYPE_TEXT_MARKETING;

    case 'SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL':
      return SubscriptionTypeName.SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL;

    default:
      return 'N/A';
  }
}