import _getSubscriberFlowLabel_SettingsUI_SubscriberUploadJob from "./__generated__/getSubscriberFlowLabel_SettingsUI_SubscriberUploadJob.graphql";
import { graphql, readInlineData } from '@attentive/data';
import GetSubscriberFlowFragment from './__generated__/getSubscriberFlowLabel_SettingsUI_SubscriberUploadJob.graphql';
import { SubscriptionFlowDescriptions } from '../components/SubscriberUploadDialog/reducers';
_getSubscriberFlowLabel_SettingsUI_SubscriberUploadJob;
export function getSubscriberFlowLabel(queryRef) {
  var _SubscriptionFlowDesc, _SubscriptionFlowDesc2;

  const {
    parameters,
    subscriptionFlow
  } = readInlineData(GetSubscriberFlowFragment, queryRef);

  if (parameters.strategy === 'SUBSCRIBER_UPDATE_STRATEGY_UPDATE_EXISTING') {
    return null;
  }

  const subscriptionType = parameters.subscriptionTypes;
  const typedSubscriptionFlow = subscriptionFlow;
  return ((_SubscriptionFlowDesc = SubscriptionFlowDescriptions[subscriptionType]) === null || _SubscriptionFlowDesc === void 0 ? void 0 : (_SubscriptionFlowDesc2 = _SubscriptionFlowDesc[typedSubscriptionFlow]) === null || _SubscriptionFlowDesc2 === void 0 ? void 0 : _SubscriptionFlowDesc2.title) || 'N/A';
}