/**
 * @generated SignedSource<<ab8ee2b04b204bc4124eb59d4db12dd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SubscriberUploadSubscriptionType = "SUBSCRIPTION_TYPE_EMAIL_CHECKOUT_ABANDONED" | "SUBSCRIPTION_TYPE_EMAIL_MARKETING" | "SUBSCRIPTION_TYPE_EMAIL_TRANSACTIONAL" | "SUBSCRIPTION_TYPE_TEXT_CHECKOUT_ABANDONED" | "SUBSCRIPTION_TYPE_TEXT_MARKETING" | "SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL" | "SUBSCRIPTION_TYPE_UNKNOWN" | "%future added value";
export type SubscriberUploadUpdateStrategy = "SUBSCRIBER_UPDATE_STRATEGY_OVERWRITE" | "SUBSCRIBER_UPDATE_STRATEGY_REMOVE_DUPLICATES" | "SUBSCRIBER_UPDATE_STRATEGY_UNKNOWN" | "SUBSCRIBER_UPDATE_STRATEGY_UPDATE_EXISTING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type getSubscriberLabel_SettingsUI_SubscriberUploadJobParameters$data = {
  readonly strategy: SubscriberUploadUpdateStrategy;
  readonly subscriptionTypes: SubscriberUploadSubscriptionType;
  readonly " $fragmentType": "getSubscriberLabel_SettingsUI_SubscriberUploadJobParameters";
};
export type getSubscriberLabel_SettingsUI_SubscriberUploadJobParameters$key = {
  readonly " $data"?: getSubscriberLabel_SettingsUI_SubscriberUploadJobParameters$data;
  readonly " $fragmentSpreads": FragmentRefs<"getSubscriberLabel_SettingsUI_SubscriberUploadJobParameters">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getSubscriberLabel_SettingsUI_SubscriberUploadJobParameters"
};

(node as any).hash = "79ccde3e8449b6741e94a84d36598626";

export default node;
